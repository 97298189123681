
/* injects from baggage-loader */

import * as React from 'react';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

import {MODALS} from '../../../../actions/modal';
import {urls} from '../../../../routes';
import {getTheme, isThemeRequired, isThemeLoaded} from '../../../../utils/theme';
import {history} from '../../../../history';

interface IMessages {
    setModal: (modalType: MODALS, args?: object) => void;
    hideAllModals: () => void;
    clearMessages: () => void;
}

export const messages = (props: IMessages) => {
    return {
        LOGIN_FAILURE: {
            invalid_grant: {
                title: i18next.t('Prisijungimo klaida'),
                message: i18next.t('Vartotojo vardas arba slaptažodis yra neteisingi.')
            },
            unactive_user: {
                title: i18next.t('Prisijungimo klaida'),
                message: i18next.t('Vartotojas nėra aktyvuotas. Registracijos metu tau išsiuntėme el.laišką, prašome paspausti jame esančią nuorodą. Tada galėsi prisijungti.')
            }
        },
        REGISTER_USER_FAILURE: {
            default: {
                title: i18next.t('Registracijos klaida'),
                message: i18next.t('O ne! Kažkas nutiko. Klaida yra dalis progreso, atkreipsime į ją dėmesį, o tu pamėgink dar kartą.')
            }
        },
        ACTIVATE_NEWSLETTER_FAILURE: {
            default: {
                title: i18next.t('Aktyvacijos klaida'),
                message: i18next.t('Vartotojo aktyvavimas nepavyko.')
            }
        },
        ACTIVATE_NEWSLETTER_SUCCESS: {
            default: {
                title: i18next.t('Vartotojo aktyvacija'),
                message: i18next.t('Vartotojas aktyvuotas.'),
            }
        },
        ACTIVATE_USER_FAILURE: {
            default: {
                title: i18next.t('Aktyvacijos klaida'),
                message: i18next.t('Vartotojo aktyvavimas nepavyko.')
            }
        },
        ACTIVATE_USER_SUCCESS: {
            default: {
                title: i18next.t('Vartotojo aktyvacija'),
                message: i18next.t('Vartotojas aktyvuotas. Prašome prisijungti.'),
                button: (
                    <button
                        className='btn btn-primary'
                        onClick={() => {
                            props.clearMessages();
                            props.setModal(MODALS.USER_JOIN, {loginVisible: true});
                        }}
                    >
                        <Trans>Prisijunk</Trans>
                    </button>
                )
            }
        },
        REMIND_PASSWORD_SUCCESS: {
            default: {
                title: i18next.t('Slaptažodžio priminimas'),
                message: i18next.t('El. laiškas su slaptažodžio priminimo nuoroda išsiųstas.')
            }
        },
        RESET_PASSWORD_FAILURE: {
            'Password reminder is invalid': {
                title: i18next.t('Slaptažodžio keitimas nepavyko'),
                message: i18next.t('O ne! Kažkas nutiko. Klaida yra dalis progreso, atkreipsime į ją dėmesį, o tu pamėgink dar kartą.')
            }
        },
        RESET_PASSWORD_SUCCESS: {
            default: {
                title: i18next.t('Slaptažodis pakeistas'),
                message: i18next.t('Valio! Slaptažodis sėkmingai pakeistas.'),
                button: (
                    <button
                        className='btn btn-primary'
                        onClick={() => {
                            props.clearMessages();
                            props.setModal(MODALS.USER_JOIN, {loginVisible: true});
                        }}
                    >
                        Prisijunk
                    </button>
                )
            }
        },
        UPDATE_DATA_USER_SUCCESS: {
            default: {
                title: i18next.t('Duomenys atnaujinti'),
                message: i18next.t('Tavo duomenys atnaujinti sėkmingai.')
            }
        },
        REGISTER_USER_SUCCESS: {
            default: {
                title: i18next.t('Registracija sėkminga'),
                message: i18next.t('Registracija sėkminga. Patikrink paštą ir aktyvuok savo vartotoją.')
            }
        },
        CHANGE_PASSWORD_USER_SUCCESS: {
            default: {
                title: i18next.t('Slaptažodžio keitimas'),
                message: i18next.t('Slaptažodis pakeistas sėkmingai.')
            }
        },
        RESEND_TICKET_SUCCESS: {
            default: {
                title: i18next.t('Bilietai išsiųsti'),
                message: i18next.t('Bilietai sėkmingai išsiųsti draugui!')
            }
        },
        TIME_OUT: {
            default: {
                title: i18next.t('Sesijos laikas baigėsi'),
                message: i18next.t('Trumpam užsnūdai? Sesija baigėsi – prisijunk iš naujo.'),
                button: (
                    <button
                        className='btn btn-primary'
                        onClick={() => {
                            props.clearMessages();
                            props.hideAllModals();

                            if (isThemeRequired() && isThemeLoaded()) {
                                const themeParams = getTheme();
                                themeParams.enableEventPage
                                    ? history.goBack()
                                    : window.location.href = (themeParams.referrerUrl || themeParams.homeUrl);
                            } else {
                                history.push(urls.get('home'));
                            }
                        }}
                    >
                        <Trans>Rinkis bilietus iš naujo</Trans>
                    </button>
                )
            }
        },
        BOX_USER_TIME_OUT: {
            default: {
                title: i18next.t('Sesijos laikas baigėsi'),
                message: i18next.t('Sesija baigėsi – prisijunk iš naujo.'),
                button: (
                    <button
                        className='btn btn-primary'
                        onClick={() => {
                            props.clearMessages();
                            history.push(urls.get('home'));
                        }}
                    >
                        <Trans>Rinkis bilietus iš naujo</Trans>
                    </button>
                )
            }
        },
        ANONYMOUS_ORDER_TIME_OUT: {
            default: {
                title: i18next.t('Saugumo sumetimais bilietų ir sąskaitų faktūrų neberodome'),
                message: i18next.t('Tačiau primename, kad savo bilietus ir sąskaitą faktūrą visada rasi el. pašte.')
            }
        },
        FACEBOOK_LOGIN_FAILURE: {
            default: {
                title: i18next.t('Prisijungimas su Facebook nepavyko'),
                message: i18next.t('Tam, kad taptum kakava.lt vartotoju, mes turime turėti tavo el.pašto adresą. Neturėdami tavo el. pašto, negalėsime tau išsiųsti bilietų, pateikti specialių pasiūlymų ar kitos informacijos. Todėl spausk dar kartą mygtuką, sutik mums duoti savo duomenis, ir prisijunk prie mūsų!'),
            }
        },
        GOOGLE_LOGIN_FAILURE: {
            default: {
                title: i18next.t('Prisijungimas su Google nepavyko'),
                message: i18next.t('Tam, kad taptum kakava.lt vartotoju, mes turime turėti tavo el.pašto adresą. Neturėdami tavo el. pašto, negalėsime tau išsiųsti bilietų, pateikti specialių pasiūlymų ar kitos informacijos. Todėl spausk dar kartą mygtuką, sutik mums duoti savo duomenis, ir prisijunk prie mūsų!'),
            }
        },
        SUBSCRIBE_SUCCESS: {
            default: {
                title: i18next.t('Naujienlaiškis'),
                message: i18next.t('Naujienlaiškis sėkmingai užsakytas.'),
                code: 200
            }
        },
        POS_PRINT_SUCCESS: {
            default: {
                title: i18next.t('POS Spausdinimas'),
                message: i18next.t('Operacija baigta.'),
                code: 200,
                button: (urlParams) => {
                    return (
                        <button
                            className='btn btn-primary'
                            onClick={() => {
                                props.clearMessages();
                                history.push(urls.get('event:tickets', urlParams));
                            }}
                        >
                            <Trans>Grįžti į renginį</Trans>
                        </button>
                    );
                }
            }
        },
        APPLY_VOUCHERS_FAILURE: {
            default: {
                hideModal: true
            }
        },

        ADD_RETURN_TICKETS_FAILURE: {
            default: {
                hideModal: true
            }
        },
        AUTO_PAYMENT: {
            default: {
                title: i18next.t('Laukiame apmokėjimo'),
                message: i18next.t('Laukiame krepšelio apmokėjimo. Prašome neuždaryti lango.'),
            }
        },
        GET_RESERVATION_PAYMENT_CART_FAILURE: {
            default: {
                hideModal: true
            }
        },
    };
};
