
/* injects from baggage-loader */

export const MODAL = {
    SHOW_MODAL: 'SET_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',
    HIDE_TOP_MODAL: 'HIDE_TOP_MODAL',
    CHANGE_ARGUMENTS: 'CHANGE_MODAL_ARGUMENTS',
    HIDE_ALL: 'HIDE_ALL_MODALS',
};

export enum MODALS {
    CHANGE_LOST_PASSWORD = 'CHANGE_LOST_PASSWORD',
    REMIND_PASSWORD = 'REMIND_PASSWORD',
    USER_JOIN = 'USER_JOIN_MODAL',
    DISCOUNT = 'DISCOUNT_MODAL',
    MOBILE_SHOW_LOCATION_AND_PLACE = 'MOBILE_SHOW_LOCATION_AND_PLACE',
    INVOICE = 'INVOICE',
    CLUBLODGE = 'CLUBLODGE',
    PACK = 'PACK',
    SEND_TICKETS = 'SEND_TICKETS',
    SHARE_SHOW = 'SHARE_SHOW',
    SEARCH = 'SEARCH',
    IS_PAID = 'IS_PAID',
    FILTER = 'FILTER',
    NOT_PAID = 'NOT_PAID',
    DOWNLOAD_BO_REPORT = 'DOWNLOAD_BO_REPORT',
    RETURN_TICKET = 'RETURN_TICKET',
    RESERVATION_PAYMENT = 'RESERVATION_PAYMENT',
    CONFIRM = 'CONFIRM',
    APPLY_VOUCHER = 'APPLY_VOUCHER',
    PRE_SALE = 'PRE_SALE',
    LOCK = 'LOCK',
}

export function setModal(modalType: MODALS, args: object = {}) {
    return {
        type: MODAL.SHOW_MODAL,
        payload: {
            modalType,
            arguments: args
        }
    };
}

export function hideModal(index) {
    return {
        type: MODAL.HIDE_MODAL,
        index
    };
}

export function hideTopModal() {
    return {
        type: MODAL.HIDE_TOP_MODAL
    };
}

export function changeModalArguments(modalType: MODALS, args: object = {}) {
    return {
        type: MODAL.CHANGE_ARGUMENTS,
        payload: {
            modalType,
            arguments: args,
        }
    };
}

export function hideAllModals() {
    return {
        type: MODAL.HIDE_ALL,
    };
}
