
/* injects from baggage-loader */

import * as React from 'react';

import {Redirect, Route, Router, Switch} from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import * as ScrollMemory from 'react-router-scroll-memory';
import LoadingBar from 'react-redux-loading-bar';
import { Helmet } from 'react-helmet';

import {history} from './history';
import {ROUTES_MAP} from './routes';
import { siteMeta } from './constants/siteMeta';

const HomeContainer = React.lazy(() => import(/* webpackChunkName: "home" */'./pages/Home/HomeContainer'));
const EventsContainer = React.lazy(() => import(/* webpackChunkName: "events" */'./pages/Events/EventsContainer'));
const GroupsContainerConnected = React.lazy(() => import(/* webpackChunkName: "groups" */'./pages/Groups/GroupsContainer'));
const GroupContainerConnected = React.lazy(() => import(/* webpackChunkName: "groups" */'./pages/Groups/GroupContainer'));
const PersonalDiscountsContainer = React.lazy(() => import(/* webpackChunkName: "events" */'./pages/Events/PersonalDiscountsContainer'));
const StaticPageContainer = React.lazy(() => import(/* webpackChunkName: "static" */'./pages/StaticPage/StaticPageContainer'));
const NewsContainer = React.lazy(() => import(/* webpackChunkName: "static" */'./pages/News/NewsContainer'));
const CartContainer = React.lazy(() => import(/* webpackChunkName: "cart" */'./pages/Cart/CartContainer'));
const VouchersContainer = React.lazy(() => import(/* webpackChunkName: "vouchers" */'./pages/Voucher/VouchersContainer'));
const VoucherContainer = React.lazy(() => import(/* webpackChunkName: "voucher" */'./pages/Voucher/VoucherContainer'));
const VoucherPurchaseContainer = React.lazy(() => import(/* webpackChunkName: "buyVouchers" */'./pages/Voucher/VoucherPurchaseContainer'));
const CartPaymentContainer = React.lazy(() => import(/* webpackChunkName: "cartPayment" */'./pages/Cart/CartPaymentContainer'));
const EventContainer = React.lazy(() => import(/* webpackChunkName: "event" */'./pages/Event/EventContainerConnected'));
const TicketsContainer = React.lazy(() => import(/* webpackChunkName: "tickets" */'./pages/Tickets/TicketsContainer'));
const BoxOfficeAdsContainer = React.lazy(() => import(/* webpackChunkName: "posAds" */'./pages/BoxOffice/boxOfficeAds/BoxOfficeAdsContainer'));
const UserTickets = React.lazy(() => import(/* webpackChunkName: "uTickets" */'./pages/User/tickets/UserTickets'));
const UserSettings = React.lazy(() => import(/* webpackChunkName: "uSettings" */'./pages/User/edit/UserSettings'));
const UserFavoriteEvents = React.lazy(() => import(/* webpackChunkName: "uFav" */'./pages/User/favorite/UserFavoriteEvents'));
const UserClubLodgeEvents = React.lazy(() => import(/* webpackChunkName: "uClubLodge" */'./pages/User/clubLodge/UserClubLodgeEvents'));
const ReturnTicketsContainer = React.lazy(() => import(/* webpackChunkName: "bo-returnTickets" */'./pages/ReturnTickets/ReturnTicketsContainer'));
const ReturnEventContainer = React.lazy(() => import(/* webpackChunkName: "bo-returnTickets" */'./pages/ReturnTickets/ReturnEventContainer'));
const ModalController = React.lazy(() => import(/* webpackChunkName: "modal" */'./pages/common/components/ModalController'));
const SearchContainer = React.lazy(() => import(/* webpackChunkName: "search" */'./pages/Search/SearchContainer'));
const PageContainer = React.lazy(() => import(/* webpackChunkName: "search" */'./pages/Page/PageContainer'));
const TicketReturnContainer = React.lazy(() => import(/* webpackChunkName: "ticketReturn" */'./pages/StaticPage/TicketReturnContainer'));
const TicketChangeToVoucherContainer = React.lazy(() => import(/* webpackChunkName: "ticketVoucher" */'./pages/StaticPage/TicketChangeToVoucherContainer'));


import {SuccessfulPurchaseSplashScreen} from './pages/BoxOffice/SuccessfulPurchaseSplashScreen';

import {RouteHOC} from './pages/common/components/utils/RouteHOC';
import {Translation} from './utils/Translation/Translation';
import {MessagesContainer} from './pages/common/components/messages/MessagesContainer';
import {secureComponent} from './pages/common/components/utils/SecureComponent';
import {LoginPageContainer} from './pages/User/LoginPageContainer';
import {notSecureComponent} from './pages/common/components/utils/NotSecureComponent';
import {BoxOfficeLoginRedirect} from './pages/BoxOffice/BoxOfficeLoginRedirect';
import {BoxOfficeMirrorWindow} from './pages/BoxOffice/BoxOfficeMirrorWindow';
import {BoxOfficeAdsWindow} from './pages/BoxOffice/boxOfficeAds/BoxOfficeAdsWindow';
import {JsonLdCorporateContacts} from './pages/common/components/site/Contacts';
import {themeLoader} from './pages/common/components/theme/ThemeLoader';
import {themeHomeRedirect} from './pages/common/components/theme/ThemeHomeRedirectComponent';
import {themeEventRedirect} from './pages/common/components/theme/ThemeEventRedirectComponent';
import {FreshChat} from './pages/common/components/freshchat/FreshChat';
import {TicketHubContainer} from './pages/StaticPage/TicketHubContainer';
import { pushPageView } from './utils/dataLayer/pageView';

const RootRoutes = () => {
    React.useEffect(() => {
        pushPageView();
    }, []);
    
    return (
        <Router history={history}>
            <ScrollToTop>
                <Helmet titleTemplate={'%s' + siteMeta.titleSuffix} htmlAttributes={{ lang: Translation.getCurrentLanguage() }} />
                <React.Suspense fallback={<div />}>
                    <Switch>
                        <Redirect from="/:url*(/+)" to={history.location.pathname.slice(0, -1)} />
                        <RouteHOC exact={true} path={ROUTES_MAP['home']} component={themeLoader(themeHomeRedirect(HomeContainer))} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['form:activate-newsletter']} component={HomeContainer} />
                        <RouteHOC path={ROUTES_MAP['user:activate']} component={HomeContainer} />
                        <RouteHOC path={ROUTES_MAP['user:change-password']} component={HomeContainer} />
                        <RouteHOC path={ROUTES_MAP['user:login']} component={notSecureComponent(LoginPageContainer)} />
                        <RouteHOC path={ROUTES_MAP['events']} component={EventsContainer} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['personal-discounts']} component={secureComponent(PersonalDiscountsContainer)} waitForAuthentication={true} />
                        <RouteHOC exact={true} path={ROUTES_MAP['events:filter']} component={EventsContainer} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['groups']} component={GroupsContainerConnected} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['group']} component={GroupContainerConnected} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['event']} component={themeLoader(themeEventRedirect(EventContainer))} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['event:noshow']} component={themeLoader(EventContainer)} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['event:tickets:club-lodge:pos']} component={TicketsContainer} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['event:tickets:club-lodge']} component={themeLoader(TicketsContainer)} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['event:tickets']} component={themeLoader(TicketsContainer)} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['voucher:buy']} component={VoucherPurchaseContainer} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['voucher:list']} component={VouchersContainer} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['voucher']} component={VoucherContainer} canMirror={true} />
                        <RouteHOC path={ROUTES_MAP['user:tickets']} component={secureComponent(UserTickets)} />
                        <RouteHOC path={ROUTES_MAP['user:settings']} component={secureComponent(UserSettings)} />
                        <RouteHOC path={ROUTES_MAP['user:favorite']} component={secureComponent(UserFavoriteEvents)} />
                        <RouteHOC path={ROUTES_MAP['user:club-lodge-events']} component={secureComponent(UserClubLodgeEvents)} />
                        <RouteHOC path={ROUTES_MAP['tickets']} component={themeLoader(UserTickets)} waitForAuthentication={true} />
                        <RouteHOC path={ROUTES_MAP['box-office:ticket-purchase-success']} component={SuccessfulPurchaseSplashScreen} canMirror={true} />
                        <RouteHOC exact={true} path={ROUTES_MAP['box-office:ads']} component={BoxOfficeAdsContainer} waitForAuthentication={true} />
                        <RouteHOC path={ROUTES_MAP['search']} component={SearchContainer} canMirror={true} />
                        <RouteHOC exact={true} path={ROUTES_MAP['cart:payment']} component={themeLoader(CartPaymentContainer)} waitForAuthentication={true} canMirror={true} />
                        <RouteHOC exact={true} path={ROUTES_MAP['cart:payment:error']} component={themeLoader(CartPaymentContainer)} waitForAuthentication={true} />
                        <RouteHOC exact={true} path={ROUTES_MAP['cart:payment:error:type']} component={themeLoader(CartPaymentContainer)} waitForAuthentication={true} />
                        <RouteHOC exact={true} path={ROUTES_MAP['cart']} component={themeLoader(CartContainer)} canMirror={true} />
                        <RouteHOC exact={true} path={ROUTES_MAP['cart:id']} component={themeLoader(CartContainer)} canMirror={true} />
                        <RouteHOC exact={true} path={ROUTES_MAP['box-office:return-ticket']} component={ReturnTicketsContainer} canMirror={true} waitForAuthentication={true} />
                        <RouteHOC exact={true} path={ROUTES_MAP['news']} component={NewsContainer} canMirror={true} />
                        <RouteHOC exact={true} path={ROUTES_MAP['news:slug']} component={NewsContainer} canMirror={true} />
                        <RouteHOC
                            exact={true}
                            path={ROUTES_MAP['box-office:return-ticket:show']}
                            component={ReturnEventContainer}
                            canMirror={true}
                            waitForAuthentication={true}
                        />
    
                        {/*<RouteHOC path={ROUTES_MAP['ticket-hub']} component={TicketHubContainer} /> /!* @TODO FIXME PROBABLY USELESS *!/*/}
                        {/*<RouteHOC path={ROUTES_MAP['ticket-return']} component={TicketReturnContainer} />*/}
                        {/*<RouteHOC path={ROUTES_MAP['ticket-return:tax']} component={TicketReturnContainer} />*/}
                        {/*<RouteHOC path={ROUTES_MAP['ticket-change']} component={TicketReturnContainer} />*/}
                        {/*<RouteHOC path={ROUTES_MAP['ticket-change:tax']} component={TicketReturnContainer} />*/}
                        <RouteHOC path={ROUTES_MAP['ticket-change-to-voucher']} component={TicketChangeToVoucherContainer} />
                        <RouteHOC path={ROUTES_MAP['static']} component={themeLoader(PageContainer)} />
                    </Switch>
                    <ModalController />
                </React.Suspense>
                <ScrollMemory />
                <LoadingBar className={'loadingBar'} />
                <MessagesContainer />
                <BoxOfficeLoginRedirect />
                <BoxOfficeAdsWindow />
                <BoxOfficeMirrorWindow />
                {/*<FreshChat />*/}
            </ScrollToTop>
        </Router>
    )
};

export default RootRoutes;
