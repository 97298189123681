
/* injects from baggage-loader */

import {RSAA, getJSON} from 'redux-api-middleware';
import {apiActionCreator} from '../utils/api/apiActionCreator';
import {API_URL} from '../utils/api/constants';
import { isBoxOfficeUser } from '../selectors/user';
import { IVenueComponentEvent } from '../contracts/tickets';
import {IReduxStore} from '../contracts/store';
import {getPreSaleCode} from '../selectors/shows';
import {history} from '../history';
import {urls} from '../routes';

export const VENUE = {
    GET: 'GET_VENUE',
    GET_LINK: 'GET_VENUE_LINK',
    CLEAR_VENUE: 'CLEAR_VENUE',
    VENUE_COMPONENT_LOADED: 'VENUE_COMPONENT_LOADED',
    VENUE_COMPONENT_POSITION_CHANGE: 'VENUE_COMPONENT_POSITION_CHANGE',
    VENUE_COMPONENT_PIVOT_CHANGE: 'VENUE_COMPONENT_PIVOT_CHANGE',
    VENUE_COMPONENT_ZOOM_CHANGE: 'VENUE_COMPONENT_ZOOM_CHANGE',
    VENUE_COMPONENT_SEAT_SELECTED: 'VENUE_COMPONENT_SEAT_SELECTED',
    VENUE_COMPONENT_SECTOR_CHANGE: 'VENUE_COMPONENT_SECTOR_CHANGE',
};

export function getVenue(showGuid: string, showClubSeats: boolean = null, clubCode: string = null, lockedSpaceCode: string = null) {
    return (dispatch, getState) => {
        const state: IReduxStore = getState();
        const _isBoxOfficeUser = isBoxOfficeUser(state);
        if (showClubSeats === null) {
            showClubSeats = state.venue && state.venue.clubTickets || false;
        }
        if (clubCode === null) {
            clubCode = state.venue && state.venue.clubCode || null;
        }

        const body = {
            boxOffice: _isBoxOfficeUser,
            showClubSeats,
            clubCode,
        };
        if (clubCode !== null) {
            Object.assign(body, {clubCode});
        }
        const presaleCode = getPreSaleCode(state, showGuid);
        if (typeof presaleCode !== 'undefined' && presaleCode !== false) {
            Object.assign(body, {presaleCode});
        }
        if (lockedSpaceCode != null) {
            Object.assign(body, {lockedSpaceCode});
        }
        console.log(body);
        dispatch(apiActionCreator({
            endpoint: API_URL + `/venue/show/${showGuid}`,
            method: 'GET',
            type: VENUE.GET,
            body,
            onError: (error, {status}) => {
                if (status === 404) {
                    history.push(urls.get('home'));
                }
            }
        }));
    };
}

export function clearVenue() {
    return {
        type: VENUE.CLEAR_VENUE
    };
}

export function onVenueLoad(e: IVenueComponentEvent) {
    return {
        type: VENUE.VENUE_COMPONENT_LOADED,
        payload: {canvasScale: e.detail.canvasScale, canvasWidth: e.detail.screenWidth}
    };
}

export function getVenueLink() {
    return apiActionCreator({
        endpoint: API_URL + '/venue/componenturl',
        method: 'GET',
        type: VENUE.GET_LINK
    });
}
